<template>
  <div class="py-4">
    <TableCustomer
      :paging="paging"
      :data="customers"
      type="COACHING"
      @sort="sortBy"
      @assign="assign"
      @perform="perform"
    />
    <ModalAssign
      v-if="showModal"
      :show="showModal"
      :customer="selectedCustomer"
      :isActiveAccount="true"
      @reset="resetModal"
      @reload-data="$emit('reload-data')"
      @open-modal-mapping="openModalMapping"
    />
    <ModalMappingValue
      :customerId="selectedCustomer && selectedCustomer.accountId"
      :groupId="selectedGroup && selectedGroup.id"
      @reset="resetModalMapping"
    />
  </div>
</template>
<script>
export default {
  name: 'WaitCoaching',
  components: {
    TableCustomer: () => import('./TableCustomer.vue'),
    ModalAssign: () => import('./ModalAssign.vue'),
    ModalMappingValue: () => import('./ModalMappingValue.vue'),
  },
  props: {
    customers: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      sort: {
        by: null,
        order: null,
      },
      selectedCustomer: null,
      selectedGroup: null,
      showModal: false,
    };
  },
  watch: {
    'paging.page'(val) {
      this.$emit('page', val);
    },
    'paging.pageSize'(val) {
      this.$emit('size', val);
    },
    total(val) {
      val && (this.paging.total = val);
    },
  },
  methods: {
    sortBy(val) {
      this.$emit('sort', val);
    },
    assign(customer) {
      this.selectedCustomer = customer;
      this.showModal = true;
    },
    perform(customer) {
      if (!customer.calendar.meetingLink) return;
      window.open(customer.calendar.meetingLink);
    },
    resetModal() {
      this.showModal = false;
      this.selectedCustomer = null;
    },
    async openModalMapping(group) {
      this.selectedGroup = group;
      await this.delay(50);
      this.showModal = false;
      this.$bvModal.show('modal-mapping-value');
    },
    resetModalMapping() {
      this.selectedGroup = null;
      this.showModal = true;
    },
  },
};
</script>
<style lang=""></style>
